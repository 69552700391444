import {USER_ROLE, APP_TYPE, RES_STATUS, AWS_DATASTORE_SCHEMA_NAMES} from "../utils/constants.js";
import {getClientUid} from "../utils/localStorage.js";
import {customAsyncForEach, isDefined, showAwsExceptionToastMessage, isActiveInternetConnection, showToastMessage} from "../utils/utils.js";

import * as AmplifyDbController from "./datastore-db.js";
import * as AmplifyAuthController from "./auth-db.js";
import * as AWS_AdminSDK from "../aws-amplify/aws-admin-functions.js";
import * as AmplifyStorageController from "./storage-db.js";

export const loginUser = async (username, password, showCatchError = true) => {
    return await AmplifyAuthController.loginUser(username, password, showCatchError);
};

export const logoutUser = async (username, password) => {
    return await AmplifyAuthController.logoutUser();
};

export const loginUserToApp = async (uuid, app, role, loginTime = null) => {
    let data = {};
    if (loginTime === null) {
        loginTime = moment().format();
    }
    if (app === APP_TYPE.admin) {
        data = {
            adminAppLastLogin: loginTime,
        };
    } else if (app === APP_TYPE.pos) {
        data = {
            posAppLastLogin: loginTime,
        };
    }
    // if (role === USER_ROLE.ADMIN.id) {
    //     return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, uuid, data);
    // } else {
    //     return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, uuid, data);
    // }
    return await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, uuid, data);
};

export const isLoggedIn = (callback, returnUserCred = false) => {
    AmplifyAuthController.isLoggedIn(returnUserCred).then(callback);
};

export const setDeviceTokenToAdminUser = async (uuid, deviceData = {}, uidData = {}, appType = 'web') => {
    if ($.isEmptyObject(deviceData) === false) {
        let updateKey = appType === 'electron' ? 'oneSignalDeviceTokens' : 'deviceTokens';
        let uidDocumentData = uidData?.clientUid ? JSON.parse(JSON.stringify(uidData)) : await getDataUserAdmin(uuid).then(data => JSON.parse(JSON.stringify(data ?? {})));
        let postData = {[updateKey]: deviceData};
        let deviceIDs = Object.keys(deviceData);
        if (typeof uidDocumentData[updateKey] !== "undefined") {
            if (uidDocumentData[updateKey] === null) {
                uidDocumentData[updateKey] = {};
            }
            uidDocumentData[updateKey][deviceIDs[0]] = deviceData[deviceIDs[0]];
            postData[updateKey] = uidDocumentData[updateKey];
        }
        localStorage.setItem('logged-in-token', deviceIDs[0]);
        await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, uuid, postData);
    }
};

export const setDeviceTokenToKickerAdminUser = async (uuid, deviceData = {}, uidData = {}, appType = 'web') => {
    if ($.isEmptyObject(deviceData) === false) {
        let updateKey = appType === 'electron' ? 'oneSignalDeviceTokens' : 'deviceTokens';
        let uidDocumentData = uidData?.clientUid ? JSON.parse(JSON.stringify(uidData)) : await getDataUserAdminKicker(uuid).then(data => JSON.parse(JSON.stringify(data ?? {})));
        let postData = {[updateKey]: deviceData};
        let deviceIDs = Object.keys(deviceData);
        if (typeof uidDocumentData[updateKey] !== "undefined") {
            if (uidDocumentData[updateKey] === null) {
                uidDocumentData[updateKey] = {};
            }
            uidDocumentData[updateKey][deviceIDs[0]] = deviceData[deviceIDs[0]];
            postData[updateKey] = uidDocumentData[updateKey];
        }
        localStorage.setItem('logged-in-token', deviceIDs[0]);
        await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsKicker, uuid, postData);
    }
};

export const setDeviceTokenToClientUser = async (uuid, deviceData = {}, appType = 'web') => {
    if ($.isEmptyObject(deviceData) === false) {
        let updateKey = appType === 'electron' ? 'oneSignalDeviceTokens' : 'deviceTokens';
        let uidDocumentData = await getDataCustomer(uuid).then(data => JSON.parse(JSON.stringify(data ?? {})));
        let postData = {[updateKey]: deviceData};
        let deviceIDs = Object.keys(deviceData);
        if (typeof uidDocumentData[updateKey] !== "undefined") {
            if (uidDocumentData[updateKey] === null) {
                uidDocumentData[updateKey] = {};
            }
            uidDocumentData[updateKey][deviceIDs[0]] = deviceData[deviceIDs[0]];
            postData[updateKey] = uidDocumentData[updateKey];
        }
        localStorage.setItem('logged-in-token', deviceIDs[0]);
        await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, uuid, postData);
    }
};

export const setDeviceTokenToEmployeeUser = async (uuid, deviceData = {}, uidData = {}, appType = 'web') => {
    if ($.isEmptyObject(deviceData) === false) {
        let updateKey = appType === 'electron' ? 'oneSignalDeviceTokens' : 'deviceTokens';
        let uidDocumentData = uidData?.uid ? JSON.parse(JSON.stringify(uidData)) : await getDataUser(uuid).then(data => JSON.parse(JSON.stringify(data ?? {})));
        let postData = {[updateKey]: deviceData};
        let deviceIDs = Object.keys(deviceData);
        if (typeof uidDocumentData[updateKey] !== "undefined") {
            if (uidDocumentData[updateKey] === null) {
                uidDocumentData[updateKey] = {};
            }
            uidDocumentData[updateKey][deviceIDs[0]] = deviceData[deviceIDs[0]];
            postData[updateKey] = uidDocumentData[updateKey];
        }
        localStorage.setItem('logged-in-token', deviceIDs[0]);
        await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, uuid, postData);
    }
};

export const setDataUserAdmin = (uuid, postData) => {
    return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, uuid, postData);
};

export const setDataUserAdminKicker = (uuid, postData) => {
    return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsKicker, uuid, postData);
};

export const addStoreSubscriptions = (postData) => {
    return AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSubscriptions, postData);
};

export const getStoreSubscriptions = (clientUid, billingCycle = null) => {
    if (billingCycle) {
        return AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSubscriptions, [
            {fieldName: 'clientUid', conditionType: 'eq', fieldValue: clientUid},
            {fieldName: 'billingCycle', conditionType: 'eq', fieldValue: billingCycle},
        ], 'paymentDate', 'desc').then((data) => {
            return (data.length > 0) ? data[0] : {};
        }).catch(() => {
            return {};
        });
    } else {
        return AmplifyDbController.getDataFromAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSubscriptions, 'eq', 'clientUid', clientUid, 'paymentDate', 'desc').then((querySnapshot) => {
            let list = {};
            querySnapshot.forEach((doc) => {
                list[doc.id] = doc;
            });
            return list;
        }).catch(() => {
            return {};
        });
    }
};

export const getDataUserAdmin = (uuid) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, uuid);
};

export const getDataUserAdminKicker = (uuid) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsKicker, uuid);
};

export const listenDataUserAdmin = (uuid, render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: uuid},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
    ], 'name', 'asc', 'uid');
};

export const listenDataUserAdminKicker = (uuid, render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsKicker, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: uuid},
    ], 'name', 'asc', 'clientUid');
};

export const getDataUser = async (uuid) => {
    return await AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, uuid);
};

export const getDataStoreDirector = (uuid) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProUsers, uuid);
};

export const getDataCustomer = (uuid) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, uuid);
};

export const getAllSaleProAccountUsers = () => {
    return AmplifyDbController.getAllDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProUsers).then((querySnapshot) => {
        const list = {};
        querySnapshot.forEach((doc) => {
            list[doc.uid] = doc;
        });
        return list;
    });
};

export const getEmployees = async (orderByField = 'name') => {
    return await AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
    ], orderByField, 'asc').then((querySnapshot) => {
        let list = {};
        querySnapshot.forEach((doc) => {
            list[doc.uid] = doc;
        });
        return list;
    }).catch((err) => {
        console.log(err);
        return {};
    });
};

export const getEmployeesByLocations = async (locationId, orderByField = 'name') => {
    return await AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
        {fieldName: 'location', conditionType: 'contains', fieldValue: locationId},
    ], orderByField, 'asc').then((querySnapshot) => {
        let list = {};
        querySnapshot.forEach((doc) => {
            list[doc.uid] = doc;
        });
        return list;
    }).catch((err) => {
        console.log(err);
        return {};
    });
};

export const listenEmployees = (render, orderByField = 'name') => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
    ], orderByField, 'asc', 'uid');
};

export const listenEmployeesByLocations = (locationId, render, orderByField = 'name') => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
        {fieldName: 'location', conditionType: 'contains', fieldValue: locationId},
    ], orderByField, 'asc', 'uid');
};


export const listenEmployeesByStorages = (storageId, render, orderByField = 'name') => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'role', conditionType: 'eq', fieldValue: USER_ROLE.EMPLOYEE.id},
        {fieldName: 'storage', conditionType: 'contains', fieldValue: storageId},
    ], orderByField, 'asc', 'uid');
};

export const getPermission = async (empTypeId) => {
    return await AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeType, empTypeId);
};

export const listenPermission = (empTypeId, render) => {
    if (empTypeId) {
        AmplifyDbController.listenDataFromCollectionById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeType, empTypeId, render);
    } else {
        render({});
    }
};

export const listenPermissions = (render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeType, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
    ]);
};

export const getClients = () => {
    return AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, [
        {fieldName: 'clientUIDs', conditionType: 'contains', fieldValue: getClientUid()},
    ], 'name', 'asc').then(async (querySnapshot) => {
        let modifiedData = {};
        await customAsyncForEach(querySnapshot, function (snapshotData) {
            modifiedData[snapshotData.uid] = snapshotData;
        });
        return modifiedData;
    }).catch(() => {
        return {};
    });
};

export const listenAllUsers = (render) => {
    AmplifyDbController.listenAllDataFromCollection(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, async (querySnapshot) => {
        let modifiedData = {};
        await customAsyncForEach(Object.keys(querySnapshot), function (uid) {
            if (querySnapshot[uid]?.role === USER_ROLE.CLIENT.id && querySnapshot[uid]?.clientUIDs?.includes(getClientUid())) {
                modifiedData[uid] = querySnapshot[uid];
            } else if (querySnapshot[uid]?.role === USER_ROLE.EMPLOYEE.id && querySnapshot[uid]?.clientUid === getClientUid()) {
                modifiedData[uid] = querySnapshot[uid];
            }
        });
        render(modifiedData);
    }, 'name', 'asc', 'uid');
};

export const listenClients = (render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, render, [
        {fieldName: 'clientUIDs', conditionType: 'contains', fieldValue: getClientUid()},
    ], 'name', 'asc', 'uid');
};

export const addUser = (user) => {
    return AmplifyAuthController.signUpUser(user.email, user.phone, false, true).then((response) => {
        if (isDefined(response, 'code')) {
            return false;
        } else {
            user['uid'] = response;
            return AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, user);
        }
    });
};

export const updateUser = (user, id) => {
    return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, id, user);
};

export const saveUser = (id, user) => {
    return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, id, user);
};

export const updateUserWithCredentials = async (user, updateEmail = false, updatePassword = false) => {
    var result = {};

    if (isActiveInternetConnection()) {

        if (updateEmail && user?.oldEmail) {

            let emailExist = await AWS_AdminSDK.getUserData(user.email);

            if (emailExist === false) {

                let statusEmailUpdate = await AWS_AdminSDK.AWS_updateAuthEmail(user?.oldEmail, user.email);

                if (!statusEmailUpdate) {

                    result['status'] = RES_STATUS.FAILED;

                    return result;
                }

            } else {

                result['status'] = RES_STATUS.EMAIL_EXIST;

                return result;
            }
        }

        if (updatePassword) {

            let statusPasswordUpdate = await AWS_AdminSDK.AWS_updateAuthPassword(user.email, user.password);

            if (statusPasswordUpdate === false) {
                result['status'] = RES_STATUS.FAILED;
                return result;
            }
        }

    } else {

        if (updateEmail || updatePassword) {
            delete user.email;
            delete user.password;
            showToastMessage("Notice", "No internet connection. You cannot update employee account email or password.", "error");
        }
    }

    if (user?.oldEmail) {
        delete user.oldEmail;
    }

    if (user?.oldPassword) {
        delete user.oldPassword;
    }

    return await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, user.uid, user).then(status => {
        if (status) {
            result['status'] = RES_STATUS.SUCCESS;
        }
        return result;
    });
};

export const loginWithIdOrEmailPassword = (uid, email = null, password = null) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, uid).then((data) => {
        if (data) {
            if (email == null) {
                email = data.email;
            }
            if (password == null) {
                password = data.password;
            }
            return AmplifyAuthController.loginUser(email, password, false);
        }
    });
};

const createCustomerAccount = (customer) => {
    return AmplifyAuthController.signUpUser(customer.email, customer.password, false, true).then(async (response) => {
        if (isDefined(response, 'code')) {
            showAwsExceptionToastMessage(response);
            return false;
        } else {
            customer['uid'] = response;
            return AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, customer).then(async () => {
                AWS_AdminSDK.AWS_confirmNewAccount(customer?.email);
                await setAppCustomerInfo(response, customer);
                return response;
            });
        }
    });
};

const setAppCustomerInfo = async (customerUid, customerInfo) => {
    let oldCustomerInfo = await getAppCustomerInfo(customerUid);
    if (oldCustomerInfo !== undefined) {
        let locations = oldCustomerInfo?.location || [];
        if (customerInfo['location'] instanceof Array && locations instanceof Array) {
            customerInfo['location'] = customerInfo['location'].concat(locations).unique();
        } else if (customerInfo['location'] instanceof Array && locations instanceof Array === false) {
            if (!customerInfo['location'].includes(locations)) {
                customerInfo['location'].push(locations);
            }
        } else if (customerInfo['location'] instanceof Array === false && locations instanceof Array) {
            if (!locations.includes(customerInfo['location']) && customerInfo['location']) {
                locations.push(customerInfo['location']);
                customerInfo['location'] = locations;
            }
        } else {
            customerInfo['location'] = [customerInfo['location'], locations];
        }
    } else {
        oldCustomerInfo = {};
    }
    if (oldCustomerInfo['clientUIDs'] === undefined) {
        oldCustomerInfo['clientUIDs'] = [];
    }
    if (!oldCustomerInfo['clientUIDs'].includes(getClientUid())) {
        oldCustomerInfo['clientUIDs'].push(getClientUid());
    }
    customerInfo['clientUIDs'] = oldCustomerInfo['clientUIDs'];
    customerInfo['uid'] = customerUid;
    customerInfo.role = USER_ROLE.CLIENT.id;

    let checkCustomerDataExist = await AmplifyDbController.getDataFromAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, 'eq', 'uid', customerUid);

    if (checkCustomerDataExist.length > 0) {
        return await AmplifyDbController.updateDataInAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, 'eq', 'uid', customerUid, customerInfo);
    } else {
        return await AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, customerInfo);
    }
};

const getAppCustomerInfo = (customerUid) => {
    return AmplifyDbController.getDataFromAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, 'eq', 'uid', customerUid).then((response) => {
        return (response?.length > 0) ? JSON.parse(JSON.stringify(response[0])) : undefined;
    });
};

export async function uploadImportDoc(file, uuid, importType = 'product', returnOnlyUrl = false) {
    const fileExt = file.name.split('.').pop();
    const uploadName = `${uuid}-${Date.now()}.${fileExt}`;
    const filePath = `${AWS_DATASTORE_SCHEMA_NAMES.SaleProClients}/${uuid}/import/${importType}`;
    return AmplifyStorageController.uploadFileToAwsStorageBucket(uploadName, file, file.type, filePath).then(async (downloadURL) => {
        if (downloadURL === false) {
            return false;
        } else {
            return returnOnlyUrl ? downloadURL : {
                url: downloadURL,
                dir: `${filePath}/${uploadName}`,
            };
        }
    });
}
