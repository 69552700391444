import * as AppConstants from "../../../utils/constants.js";
import * as CommonUtilsFunc from "../../../utils/utils.js";

export default class RenderSystem {

    /**
     * Show or Hide loader
     * @param loaded
     */
    static showHideLoader(loaded) {
        if (!loaded) {
            $('div.loading-spinner').removeClass('hide');
        } else {
            $('div.loading-spinner').addClass('hide');
        }
    }

    /**
     * Showing the app loading dialog
     */
    static showLoadingDataMessage(
        msg = 'Loading Application Data...',
        title = false,
        closeButton = false,
        backdrop = 'static',
        keyboard = false,
    ) {
        bootbox.hideAll();
        bootbox.dialog({
            title,
            message: '<p style="text-align: center; margin-bottom: 0;">' +
                '<i class="fa fa-spin fa-spinner"></i> ' +
                msg +
                '</p>',
            closeButton,
            backdrop,
            keyboard,
        });
    }

    /**
     * Hide the app loading dialog
     */
    static hideLoadingDataMessage(instant = false) {
        if (instant) {
            $('.bootbox.modal').modal('hide');
        } else {
            setTimeout(function () {
                $('.bootbox.modal').modal('hide');
                // bootbox.hideAll();
            }, 1000);
        }
    }

    /**
     * Show bootbox alert message common function
     *
     * @param message
     * @param type
     * @param position
     * @param life
     */
    static showAlertMessage(message, type = 'success', position = 'left', life = 5000) {
        $.jnoty(message, {
            position: `top-${position}`,
            appendTo: 'body',
            glue: 'after',
            theme: type,
            themeState: 'highlight',
            corners: '5px',
            check: 250,
            life: life,
            closeDuration: 'normal',
            openDuration: 'normal',
            easing: 'swing',
            animateOpen: {
                opacity: 'show',
            },
            animateClose: {
                opacity: 'hide',
            },
        });
    }

    static modalHideFormReset() {
        $('div.modal').on('hide.bs.modal', function (event, el) {
            if ($(event.currentTarget).find('form').length > 0) {
                $(event.currentTarget).find('form')[0].reset();
            }
            if ($(event.currentTarget).find('form').find('select.select-2').length > 0) {
                $(event.currentTarget).find('form').find('select.select-2').val('').trigger('change');
            }
        });
    }

    /**
     * Print view event handler
     */
    static applyPrintEvent(printButtonElement, printAreaElement, customTitle = 'Sale PRO', elementGetValue = null, cssFilePath = '../../../common/main-section/styles/print-area.css') {
        $(printButtonElement).click(function () {
            let popupTitle = customTitle;
            if (elementGetValue !== null) {
                popupTitle = customTitle + ' ' + $(elementGetValue).text();
            }
            if ((AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'cordova-android' || AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'cordova-ios')) {
                if (window?.cordova && window?.cordova.plugins?.printer) {
                    let printContent = CommonUtilsFunc.generateCordovaPrintContent(popupTitle, $(printAreaElement).html(), false, true);
                    window.cordova.plugins.printer.print(printContent, {
                        name: popupTitle,
                        orientation: 'portrait',
                    });
                } else {
                    CommonUtilsFunc.showAlertMessage(`Something went wrong! Can't print the ticket now. Please try again later.`, 'error');
                }
            } else {
                var mode = AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'web' ? 'popup' : 'iframe';
                var option = {
                    mode: mode,
                    popClose: mode,
                    popTitle: popupTitle,
                    standard: 'html5',
                    retainAttr: ['class', 'id', 'style', 'on'],
                    extraHead: `<meta charset="utf-8" /><style>@media print{body{-webkit-print-color-adjust:exact}table thead tr th{background:cornflowerblue!important;color:black!important;border-bottom:1px solid gainsboro;position:unset!important;top:unset!important;}}</style>`,
                    extraCss: cssFilePath
                };
                $(printAreaElement).printArea(option);
            }
        });
    }
}
