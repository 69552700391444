import * as AmplifyDbController from "./datastore-db.js";
import {AWS_DATASTORE_SCHEMA_NAMES} from "../utils/constants.js";
import {getClientUid} from "../utils/localStorage.js";

export const listenAllLogs = (render) => {
    AmplifyDbController.listenAllDataFromCollection(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsActivityLogs, render, 'date', 'desc');
};

export const listenLogsByUserId = (collectionName, userId, render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsActivityLogs, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'uid', conditionType: 'eq', fieldValue: userId},
    ], 'date', 'desc');
};

export const listenLogsByDates = (startDate, endDate, render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsActivityLogs, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'date', conditionType: 'ge', fieldValue: moment(startDate).format()},
        {fieldName: 'date', conditionType: 'le', fieldValue: moment(endDate).format()},
    ], 'date', 'desc');
};

export const listenLogsByDatesAndOperation = (startDate, endDate, render, operation = 'empActivityLog') => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsActivityLogs, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'operation', conditionType: 'eq', fieldValue: operation},
        {fieldName: 'date', conditionType: 'ge', fieldValue: moment(startDate).format()},
        {fieldName: 'date', conditionType: 'le', fieldValue: moment(endDate).format()},
    ], 'date', 'asc');
};

export const getLogsByDates = (startDate, endDate) => {
    return AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsActivityLogs, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'date', conditionType: 'ge', fieldValue: moment(startDate).format()},
        {fieldName: 'date', conditionType: 'le', fieldValue: moment(endDate).format()},
    ], 'date', 'desc');
};

export const getLogsByUserId = (userId) => {
    return AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsActivityLogs, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()},
        {fieldName: 'uid', conditionType: 'eq', fieldValue: userId},
    ], 'date', 'desc');
};

export const addLog = (postData) => {
    return AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsActivityLogs, postData);
};
