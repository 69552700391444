import {changePageByName, showAwsExceptionMessage, showHidePasswordEvent} from "./common/utils/utils.js";
import {getClientUid, getLocalStorageKey, login, setClientUid, setLocalStorageKey} from "./common/utils/localStorage.js";
import {hideBlockUI, isDefined, showBlockUI} from "./common/main-section/scripts/helper/common.js";
import {KICKER_POINT_VERSION, USER_ROLE} from "./common/utils/constants.js";
import RenderSystem from "./common/main-section/scripts/render/render-system.js";
import * as UserController from "./common/db/user-db.js";
import * as SaleProConfigController from "./common/db/salepro-config-db.js";
import * as ActivityLogController from "./common/db/activity-logs-db.js";
import * as AmplifyAuthController from "./common/db/auth-db.js";
import * as AmplifyDbController from "./common/db/datastore-db.js";
import config from "./admin/config.js";

let loaded = false;
let login2ndTry = false;
const DEFAULT_LOAD = "accounting";

$(document).ready(function () {
    if (getLocalStorageKey('generalLoginEmail') === null && getLocalStorageKey('generalLoginPassword') === null) {
        localStorage.clear();
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
    }
    $('.salepro-version').text('v.' + KICKER_POINT_VERSION);
    showBlockUI();
    UserController.isLoggedIn(initSaleProLogin, true);
});

const loginFormHandler = () => {
    showHidePasswordEvent();

    $('#loginForm').validate({
        rules: {
            loginUserEmail: {
                required: true,
            },
            loginUserPassword: {
                required: true,
            },
        },
        ignore: [], errorPlacement: function errorPlacement(error, element) {
            $(element).parents('div.form-group').find('span.validation-errors').append(error);
        },
        onfocusout: false,
        highlight: function (element, errorClass) {
            $(element).addClass(errorClass);
        },
        unhighlight: function (element, errorClass) {
            $(element).removeClass(errorClass);
        },
        submitHandler: function (form) {
            if ($(form).valid()) {
                showBlockUI();
                let accountEmail = $('#loginUserEmail').val();
                let accountPassword = $('#loginUserPassword').val();
                proceedGeneralLogin(accountEmail, accountPassword);
            }
        },
    });
};

const proceedGeneralLogin = (accountEmail, accountPassword) => {
    AmplifyAuthController.loginUser(accountEmail, accountPassword, false).then((loginResponse) => {
        if (loginResponse !== undefined) {
            const clientAccountUID = loginResponse.username;
            checkAndProceedAppSelection(clientAccountUID, true);
        } else {
            RenderSystem.showAlertMessage('Invalid operation performed!', 'danger', 'right');
        }
    }).catch((error) => {
        if (error.name === 'UserNotConfirmedException') {
            hideBlockUI();
            bootbox.prompt({
                title: 'We sent a verification code on your registered email address. Please check your email and verify here with the code.',
                message: '<p class="text-bold text-danger">Check that it has not arrived in the SPAM folder of your email.</p>',
                centerVertical: true,
                closeButton: false,
                required: true,
                callback: function (confirmationCode) {
                    if (confirmationCode) {
                        showBlockUI();
                        AmplifyAuthController.confirmSignUp(accountEmail, confirmationCode).then(async (verified) => {
                            hideBlockUI();
                            if (verified) {
                                RenderSystem.showAlertMessage("Your account is now verified. You can login now.", "success", 'right');
                            } else {
                                RenderSystem.showAlertMessage("Your email has not been verified. Either you entered the wrong verification code or the code time expired. Please try again.", "warning", 'right');
                                $("form .msg-error").css("display", "block");
                            }
                        });
                    } else {
                        RenderSystem.showAlertMessage("You cancelled the verification process. Without verification you can not sign in.", "warning", 'right');
                        hideBlockUI();
                        $("form .msg-error").css("display", "block");
                    }
                }
            });
        } else {
            showAwsExceptionMessage(error);
        }
        login2ndTry = false;
        hideBlockUI();
    });
};

const checkAndProceedAppSelection = (clientAccountUID, saveLog = false) => {
    UserController.getDataUserAdminKicker(clientAccountUID).then((clientData) => {
        if (clientData !== undefined) {
            setLocalStorageKey('GENERAL_LOGIN', 'true');
            setClientUid(clientAccountUID, 'admin');
            checkSubscriptionConfigProceedNextStep(clientData, clientAccountUID, saveLog, true);
        } else {
            UserController.getDataStoreDirector(clientAccountUID).then((storePermission) => {
                if (storePermission !== undefined && storePermission?.adminAccess === true && storePermission?.adminUID) {
                    setLocalStorageKey('GENERAL_LOGIN', 'true');
                    setClientUid(storePermission.adminUID, 'admin');
                    getUserDataAndProceedLogin(clientAccountUID, saveLog);
                } else {
                    UserController.logoutUser().catch((err) => {
                    });
                    hideBlockUI();
                    RenderSystem.showAlertMessage('You do not have permission contact your administrator.', 'danger', 'right');
                    localStorage.clear();
                    caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
                    loginFormHandler();
                }
            }).catch((error) => {
                UserController.logoutUser().catch((err) => {
                });
                hideBlockUI();
                RenderSystem.showAlertMessage('Please provide valid store director credentials or contact your administrator.', 'danger', 'right');
                localStorage.clear();
                caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
                loginFormHandler();
            });
        }
    }).catch(function (error) {
        UserController.logoutUser().catch((err) => {
        });
        hideBlockUI();
        RenderSystem.showAlertMessage('Please provide valid store admin credentials.', 'danger', 'right');
        localStorage.clear();
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
        loginFormHandler();
    });
};

const initSaleProLogin = async (loginResponse) => {
    if (loaded === false) {
        if (loginResponse !== false) {
            if (isDefined(loginResponse, 'uid')) {
                let loggedInAccountID = loginResponse.uid;
                let clientAccountUID = getClientUid('admin');
                if (clientAccountUID) {

                } else {
                    clientAccountUID = loggedInAccountID;
                }

                checkAndProceedAppSelection(clientAccountUID, false);
            } else {
                UserController.logoutUser().catch((err) => {
                });
                hideBlockUI();
                localStorage.clear();
                caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
                loginFormHandler();
            }
        } else {
            if (getLocalStorageKey('generalLoginEmail') && getLocalStorageKey('generalLoginPassword')) {
                let accountEmail = getLocalStorageKey('generalLoginEmail');
                let accountPassword = getLocalStorageKey('generalLoginPassword');
                proceedGeneralLogin(accountEmail, accountPassword);
            } else {
                await AmplifyDbController.syncDataByClientAccount('', 'all', true).then(r => console.log('r')).catch(e => console.error('e'));
                hideBlockUI();
                localStorage.clear();
                caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
                loginFormHandler();
            }
        }
    }

    loaded = true;
};

const getUserDataAndProceedLogin = (uid, saveLog = false) => {
    UserController.getDataUser(uid).then(async (user) => {
        if (user !== undefined && user?.role === USER_ROLE.EMPLOYEE.id && user?.normal === true) {
            let clientData = await UserController.getDataUserAdminKicker(getClientUid('admin'));
            checkSubscriptionConfigProceedNextStep(clientData, uid, saveLog, false);
        } else {
            UserController.logoutUser().catch((err) => {
            });
            hideBlockUI();
            localStorage.clear();
            caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
            loginFormHandler();
        }
    }).catch(function () {
        UserController.logoutUser().catch((err) => {
        });
        hideBlockUI();
        localStorage.clear();
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
        loginFormHandler();
    });
};

const checkAndConfigureRememberLogin = () => {
    if ($('#remember_main_login').is(':checked')) {
        setLocalStorageKey('generalLoginEmail', $('#loginUserEmail').val());
        setLocalStorageKey('generalLoginPassword', $('#loginUserPassword').val());
    }
};

const checkSubscriptionConfigProceedNextStep = (clientData, loggedInUid, saveLog = false, storeOwnerLoggedIn = false) => {
    SaleProConfigController.getSaleProConfigs(true).then(async (siteConfigs) => {
        if (siteConfigs?.enableSubscription) {
            let freePeriodExpired = false;
            if (siteConfigs?.freePeriod > 0) {
                let freePeriodDate = moment(clientData.createdAt).add(siteConfigs.freePeriod, 'days').format('YYYY-MM-DD HH:mm:ss');
                freePeriodExpired = moment(moment().format('YYYY-MM-DD HH:mm:ss'), "YYYY-MM-DD HH:mm:ss").isAfter(freePeriodDate);
            }
            if (freePeriodExpired) {
                let currentBillingCycle = moment().startOf('month').format('DD-MM-YYYY') + ' - ' + moment().endOf('month').format('DD-MM-YYYY');
                await UserController.getStoreSubscriptions(getClientUid('admin'), currentBillingCycle).then(async (subscriptionData) => {
                    if ($.isEmptyObject(subscriptionData) === true) {
                        if (saveLog) {
                            await ActivityLogController.addLog({
                                date: moment().format(),
                                timezone: moment.tz.guess(),
                                clientUid: getClientUid('admin'),
                                uid: loggedInUid,
                                operation: 'kickerGeneralLogin',
                                currentPage: 'kickerGeneralLogin',
                                nextPage: 'subscription',
                                subscriberControlOperation: getLocalStorageKey('subscriberAdmin') === 'true',
                                storeId: '',
                                storeType: '',
                                message: '',
                                ip: {},
                            }).then(() => {
                                changePageByName("subscription.html");
                            });
                        } else {
                            changePageByName("subscription.html");
                        }
                    } else {
                        await saveLogAndRedirect(loggedInUid, saveLog, storeOwnerLoggedIn);
                    }
                }).catch(async (error) => {
                    await saveLogAndRedirect(loggedInUid, saveLog, storeOwnerLoggedIn);
                });
            } else {
                await saveLogAndRedirect(loggedInUid, saveLog, storeOwnerLoggedIn);
            }
        } else {
            await saveLogAndRedirect(loggedInUid, saveLog, storeOwnerLoggedIn);
        }
    }).catch(async (error) => {
        console.log(error);
        await saveLogAndRedirect(loggedInUid, saveLog, storeOwnerLoggedIn);
    });
};

const saveLogAndRedirect = async (loggedInUid, saveLog, storeOwnerLoggedIn) => {
    if (saveLog) {
        await ActivityLogController.addLog({
            date: moment().format(),
            timezone: moment.tz.guess(),
            clientUid: getClientUid('admin'),
            uid: loggedInUid,
            operation: 'kickerGeneralLogin',
            currentPage: 'kickerGeneralLogin',
            nextPage: 'kickerAdminApp',
            subscriberControlOperation: getLocalStorageKey('subscriberAdmin') === 'true',
            storeId: '',
            storeType: '',
            message: '',
            ip: {},
        }).then(async () => {
            await checkPermissionAndProceed(loggedInUid, saveLog, storeOwnerLoggedIn);
        });
    } else {
        await checkPermissionAndProceed(loggedInUid, saveLog, storeOwnerLoggedIn);
    }
}

const checkPermissionAndProceed = async (loggedInUid, saveLog, storeOwnerLoggedIn) => {
    checkAndConfigureRememberLogin();
    const {menu} = config;
    const defaultMenuItem = menu[DEFAULT_LOAD];
    login(loggedInUid, USER_ROLE.ADMIN.id, USER_ROLE.ADMIN.id);
    if (storeOwnerLoggedIn) {
        setLocalStorageKey('SHOW_LOADER_ICONS', 'true');
        changePageByName('/admin/' + defaultMenuItem.link);
    } else {
        let userData = await UserController.getDataUser(loggedInUid);
        if (userData?.permissions) {
            let permission = await UserController.getPermission(userData?.permissions);
            if (!permission || permission[DEFAULT_LOAD] === true || permission[DEFAULT_LOAD]?.all === true) {
                setLocalStorageKey('SHOW_LOADER_ICONS', 'true');
                changePageByName('/admin/' + defaultMenuItem.link);
            } else {
                let checkCondition = true;
                Object.keys(menu).forEach((key) => {
                    if (checkCondition) {
                        if (permission[key] === true || permission[key]?.all === true) {
                            const item = menu[key];
                            setLocalStorageKey('SHOW_LOADER_ICONS', 'true');
                            changePageByName('/admin/' + item.link);
                            checkCondition = false;
                        }
                    }
                });
            }
        } else {
            setLocalStorageKey('SHOW_LOADER_ICONS', 'true');
            changePageByName('/admin/' + defaultMenuItem.link);
        }
    }
}
