import {AWS_DATASTORE_SCHEMA_NAMES} from "../../../utils/constants.js";

export const FIREBASE_CLOUD_FUNCTION_URL = "https://europe-west1-salepro-3460c.cloudfunctions.net/salePRO_RestAPI/";

export const FIREBASE_VERIFY_AUTH_URL = FIREBASE_CLOUD_FUNCTION_URL + "verifyAuthAdmin";
export const FIREBASE_VERIFY_CAPTCHA_URL = FIREBASE_CLOUD_FUNCTION_URL + "verifyGoogleRecaptcha";
export const FIREBASE_STORAGE_URL = "https://firebasestorage.googleapis.com/v0/b/";

/**
 * Firebase collection name map
 * @type {{syncData: string, timezone: string, storage: string, smsPackage: string,
 * users: string, invoices: string, paymentMethods: string, sms: string, location: string, region: string,
 * notifications: string}}
 */
const firebaseCollectionNames = {
  saleProKickerPoint: 'SalePRO-Clients-Kicker',
  saleProSubscription: 'SalePRO-Clients-Subscriptions',
  activityLogs: 'activityLogs',
  saleProConfig: 'SalePRO-Configs',
  appointment: 'appointment',
  additions: 'additions',
  appNotifications: 'appNotifications',
  appStoreNotifications: 'appStoreNotifications',
  posEmpDashboard: 'posEmpDashboard',
  expenses: 'expenses',
  website: 'website',
  bookmark: 'bookmark',
  damages: 'damages',
  employeeType: 'employee-type',
  inventory: 'products',
  invoices: 'invoices',
  layaways: 'layaways',
  location: 'locations',
  locationCounts: 'counts',
  notifications: 'notifications',
  orders: 'orders',
  giftCards: 'gift-cards',
  paymentMethods: 'paymentMethods',
  payroll: 'payroll-type',
  salary: 'salary',
  productCategories: 'product-categories',
  region: 'regions',
  settingsBusinessCard: 'settingsBusinessCard',
  settingsBusinessCardModules: 'settingsBusinessCardModules',
  settingsCompetition: 'settingsCompetition',
  settingsCountingOptions: 'settingsCountingOptions',
  settingsEmployeeCompetition: 'settingsEmployeeCompetition',
  settingsEnabledPaymentMethods: 'settingsEnabledPaymentMethods',
  settingsGeneralInfo: 'settingsGeneralInfo',
  settingsInventory: 'settingsInventory',
  settingsLayaway: 'settingsLayaway',
  settingsOpenDay: 'settingsOpenDay',
  settingsCloseDay: 'settingsCloseDay',
  settingsLocationModules: 'settingsLocationModules',
  settingsMyWorld: 'settingsMyWorld',
  settingsObligations: 'settingsObligations',
  settingsPrintingOptions: 'settingsPrintOptions',
  settingsProductDisplayOptions: 'settingsProductDisplayOptions',
  settingsSaleOptions: 'settingsSaleOptions',
  settingsAppointmentOptions: 'settingsAppointmentOptions',
  settingsTaxExempt: 'settingsTaxExempt',
  settingsTaxRules: 'settingsTaxRules',
  settingsXReport: 'settingsXReport',
  sms: 'sms',
  smsPackage: 'Sms-Package',
  storage: 'storages',
  syncData: 'syncData',
  timezone: 'SalePRO-Timezone',
  testers: 'testers',
  transfers: 'transfers',
  users: 'users',
};

/**
 * Trigger render methods collections wise config
 * Trigger render if collection render true
 * @type {{main_page: {[p: string]: {render: boolean}|{render: boolean}|{render: boolean}|{render: boolean}|{render: boolean}|{render: boolean}|{render: boolean}|{render: boolean}|{render: boolean}|{render: boolean}|{render: boolean}|{render: boolean}}, invoice_page: {[p: string]: {render: boolean}|{render: boolean}|{render: boolean}}}}
 */
const triggerRenderCollectionConfigs = {
  main_page: {
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocations]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProSmsPackage]: {render: true},
    // [firebaseCollectionNames.sms]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsRegions]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProTimezone]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsPaymentMethods]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsStorages]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsInvoices]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmpNotificationPermissions]: {render: true},
  },
  invoice_page: {
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocations]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsStorages]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsInvoices]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProConfigs]: {render: false},
  },
  location_page: {
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsAppNotifications]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocations]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsRegions]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsProducts]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsBookmark]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsOrders]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsDamages]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsTransfers]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsTesters]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsPrintOptions]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsTaxRules]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.Prod]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsLocationModules]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsBusinessCardModules]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsCountingOptions]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsCompetition]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsSaleOptions]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsEnabledPaymentMethods]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsLayaway]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsOpenDay]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsCloseDay]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsBusinessCard]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsInventory]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsEmployeeCompetition]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsXReport]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsTaxExempt]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsMyWorld]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsObligations]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeType]: {render: false},
  },
  location_admin_page: {
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsAppNotifications]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocations]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsRegions]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsProducts]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsTesters]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsBookmark]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsOrders]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsDamages]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsTransfers]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsPrintOptions]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsTaxRules]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsProductDisplayOptions]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsLocationModules]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsBusinessCardModules]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsCountingOptions]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsCompetition]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsSaleOptions]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsEnabledPaymentMethods]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsLayaway]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsOpenDay]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsCloseDay]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsBusinessCard]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsInventory]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsEmployeeCompetition]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsXReport]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsTaxExempt]: {render: false},
    // [AWS_DATASTORE_SCHEMA_NAMES.settingsMyWorld]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsObligations]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeType]: {render: false},
  },
  reports_page: {
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsAppNotifications]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsRegions]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocations]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsProducts]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsOrders]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsDamages]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsBookmark]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeType]: {render: false},
  },
  store_credit_reports_page: {
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsRegions]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocations]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsOrders]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers]: {render: true},
  },
  layaway_reports_page: {
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocations]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsOrders]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLayaways]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers]: {render: false},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsProducts]: {render: false},
  },
  payroll_reports_page: {
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsOrders]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsBookmark]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsPayrollType]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsAdditions]: {render: true},
    [AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeSalary]: {render: false},
  },
};

/**
 * Google Recaptcha Site KEY
 * @type {string}
 */
const googleRecaptchaSiteKey = '6LcMxSkeAAAAAHRMWnKDtejWGc0rKr6yXHATEaoc';

/**
 *
 * @type {{DISCOUNT_TYPE_MONEY: string, TAX_AMOUNT_PERCT: number, DISCOUNT_TYPE_PERCT: string}}
 */
const orderConfig = {
  TAX_AMOUNT_PERCT: 21,
  DISCOUNT_TYPE_PERCT: 'perct',
  DISCOUNT_TYPE_MONEY: 'money',
};

const invoiceConfig = {
  invoiceAmount: 60,
  invoiceVAT: 21
};

const USER_ROLE = {
  ADMIN: { id: 0, page: "appointmentsadmin.html" },
  EMPLOYEE: { id: 1, page: "appointmentsstore.html" },
  CLIENT: { id: 2, page: "appointmentsclient.html" },
};

const dashboardReportsSectionsIds = ['gross_total_section', 'net_total_section', 'ProductSold-Column', 'soldMerchandiseCost-Column', 'totalLosses-Column', 'notifications_section', 'region_sales_date_chart', 'payment_methods_chart'];

export {
  firebaseCollectionNames,
  triggerRenderCollectionConfigs,
  googleRecaptchaSiteKey,
  orderConfig,
  invoiceConfig,
  USER_ROLE,
  dashboardReportsSectionsIds,
};
