export default {
    menu: {
        accounting: {
            icon: "dashboard.png",
            label: "Reports",
            link: "sections/administration/index.html",
            id: "sectionAccountingIframe",
            labelLangs: {
                ar: "التقارير",
                en: "Reports",
                zh: "报告",
                de: "Berichte",
                iw: "דיווחים",
                it: "Rapporti",
                ro: "Rapoarte",
                es: "Informes",
                tr: "Raporlar",
                fr: "Rapports",
                th: "รายงาน"
            }
        },
        inventory: {
            icon: "inventory.png",
            label: "Inventory",
            link: "sections/inventory/index.html",
            id: "sectionInventoryIframe",
            labelLangs: {
                ar: "جرد",
                en: "Inventory",
                zh: "存货",
                de: "Inventar",
                iw: "מְלַאי",
                it: "Inventario",
                ro: "Inventar",
                es: "Inventario",
                tr: "Envanter",
                fr: "Inventaire",
                th: "รายการสิ่งของ"
            }
        },
        // onlinestore: {
        //     icon: "kicker-logo-short.png",
        //     label: "Online Store",
        //     link: "sections/online-store/default/index.html",
        //     id: "sectionOnlineStoreIframe",
        //     labelLangs: {
        //         ar: "متجر على الانترنت",
        //         en: "Online Store",
        //         zh: "网上商城",
        //         de: "Online-Shop",
        //         iw: "חנות מקוונת",
        //         it: "Negozio online",
        //         ro: "Magazin online",
        //         es: "Tienda en línea",
        //         tr: "Online mağaza",
        //         fr: "Magasin en ligne",
        //         th: "ร้านค้าออนไลน์"
        //     }
        // },
        myaccount: {
            icon: "myaccount.png",
            label: "My Account",
            link: "sections/main-section/my-account.html",
            resolution: "fit",
            id: "sectionMyAccountIframe",
            labelLangs: {
                ar: "حسابي",
                en: "My Account",
                zh: "我的账户",
                de: "Mein Konto",
                iw: "החשבון שלי",
                it: "Il mio conto",
                ro: "Contul meu",
                es: "Mi cuenta",
                tr: "Hesabım",
                fr: "Mon compte",
                th: "บัญชีของฉัน"
            }
        },
        customers: {
            icon: "customers.png",
            label: "Customers",
            link: "sections/customers-marketing/index.html",
            id: "sectionCustomerIframe",
            labelLangs: {
                ar: "عملاء",
                en: "Customers",
                zh: "顾客",
                de: "Kunden",
                iw: "לקוחות",
                it: "Clienti",
                ro: "Clienți",
                es: "Clientes",
                tr: "Müşteriler",
                fr: "Les clients",
                th: "ลูกค้า"
            }
        },
        employees: {
            icon: "employees.png",
            label: "Employees",
            link: "sections/manage-employee/index.html",
            id: "sectionEmployeesIframe",
            labelLangs: {
                ar: "الموظفين",
                en: "Employees",
                zh: "雇员",
                de: "Angestellte",
                iw: "עובדים",
                it: "Dipendenti",
                ro: "Angajatii",
                es: "Empleados",
                tr: "Çalışanlar",
                fr: "Employés",
                th: "พนักงาน"
            }
        },
        orders: {
            icon: "OrdersIcon.png",
            label: "Orders",
            link: "sections/orders/index.html",
            id: "sectionOrdersIframe",
            labelLangs: {
                ar: "الطلب #٪ s",
                en: "Orders",
                zh: "命令",
                de: "Aufträge",
                iw: "הזמנות",
                it: "Ordini",
                ro: "Comenzi",
                es: "Pedidos",
                tr: "Emirler",
                fr: "Ordres",
                th: "คำสั่งซื้อ"
            }
        },
        chat: {
            icon: "chat.png",
            label: "Inbox-Chat",
            link: "sections/chat-contacts/index.html",
            id: "sectionChatIframe",
            labelLangs: {
                ar: "البريد الوارد الدردشة",
                en: "Inbox-Chat",
                zh: "收件箱聊天",
                de: "Posteingang-Chat",
                iw: "דואר נכנס-צ'אט",
                it: "Posta in arrivo-Chat",
                ro: "Inbox-Chat",
                es: "Bandeja de entrada-Chat",
                tr: "Gelen Kutusu-Sohbet",
                fr: "Boîte de réception-Chat",
                th: "Inbox-แชท"
            }
        },
        pos: {
            icon: "launcher_icon.png",
            label: "Point of Sale",
            link: "https://www.app.salepointpro.com?rf=kp",
            id: "sectionPosIframe",
            labelLangs: {
                ar: "نقطة البيع",
                en: "Point of Sale",
                zh: "销售点",
                de: "Kasse",
                iw: "נקודת המכירה",
                it: "Punto vendita",
                ro: "Punct de vânzare",
                es: "Punto de venta",
                tr: "Satış noktası",
                fr: "Point de vente",
                th: "จุดขาย"
            }
        },
        logout: {
            icon: "logout.png",
            label: "Logout",
            labelLangs: {
                ar: "تسجيل خروج",
                en: "Logout",
                zh: "登出",
                de: "Ausloggen",
                iw: "להתנתק",
                it: "Disconnettersi",
                ro: "Deconectare",
                es: "Cerrar sesión",
                tr: "Çıkış Yap",
                fr: "Se déconnecter",
                th: "ออกจากระบบ"
            }
        },
    },
};
