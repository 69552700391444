import {AWS_DATASTORE_SCHEMA_NAMES} from '../utils/constants.js';

import * as AmplifyDbController from "./datastore-db.js";

export const listenSaleProConfigs = (render) => {
    AmplifyDbController.listenAllDataFromCollection(AWS_DATASTORE_SCHEMA_NAMES.SaleProConfigs, (querySnapshot) => {
        const list = {};
        if(Object.values(querySnapshot).length > 0) {
            querySnapshot.forEach((doc) => {
                list[doc.key] = doc?.value || '';
            });
        }
        render(list);
    }, 'key', 'asc');
};

export const getSaleProConfigs = (keyWise = false) => {
    if (keyWise) {
        return AmplifyDbController.getAllDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProConfigs, 'key', 'asc').then((querySnapshot) => {
            const list = {};
            if(Object.values(querySnapshot).length > 0) {
                querySnapshot.forEach((doc) => {
                    list[doc.key] = doc?.value || '';
                });
            }
            return list;
        });
    } else {
        return AmplifyDbController.getAllDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProConfigs, 'key', 'asc').then((querySnapshot) => {
            const list = {};
            querySnapshot.forEach((doc) => {
                list[doc.key] = doc;
            });
            return list;
        });
    }
};

export const addConfig = (data) => {
    return AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProConfigs, data);
};

export const updateConfig = async (data, fieldValue = '', fieldName = 'key', conditionType = 'eq') => {
    let existingConfig = await AmplifyDbController.getDataFromAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProConfigs, conditionType, fieldName, fieldValue);
    if(existingConfig.length > 0) {
        return AmplifyDbController.updateDataInAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProConfigs, conditionType, fieldName, fieldValue, data);
    } else {
        return addConfig(data);
    }
};
