import * as Auth from 'aws-amplify/auth';
import {DataStore} from 'aws-amplify/datastore';
import {Hub} from 'aws-amplify/utils';
import * as JWT_CRYPTO from "crypto-js";

import * as AppUtilsFunctions from "../utils/utils.js";
import * as AmplifyConfigure from "../aws-amplify/amplify-configure.js";

/**
 * Sign up new user
 * @param userEmail
 * @param userPass
 * @param autoSignIn
 * @param returnCatchError
 * @returns {Promise<boolean|string>}
 */
export const signUpUser = async (userEmail, userPass, autoSignIn = true, returnCatchError = false) => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-auth.js signUpUser');

        const {isSignUpComplete, userId, nextStep} = await Auth.signUp({
            username: userEmail,
            password: userPass,
            options: {
                userAttributes: { // other custom attributes
                    email: userEmail,
                },
                autoSignIn: autoSignIn, // optional - enables auto sign in after user is confirmed
            }
        });

        console.log('signUpUser response: ', {isSignUpComplete, userId, nextStep});

        return (isSignUpComplete || nextStep?.signUpStep === 'CONFIRM_SIGN_UP') ? userId : false;

    } catch (error) {
        if (returnCatchError) {
            return error;
        } else {
            if (typeof $?.jnoty !== 'undefined') {
                AppUtilsFunctions.showAwsExceptionMessage(error);
            } else {
                AppUtilsFunctions.showAwsExceptionToastMessage(error);
            }
            return false;
        }
    }
};

/**
 * Resend verification oneTimeCode by email
 * @param userEmail
 * @returns {Promise<boolean>}
 */
export const resendConfirmationCode = async (userEmail) => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js resendConfirmationCode');
    try {
        await Auth.resendSignUpCode({username: userEmail});
        // console.log('oneTimeCode resent successfully');
        return true;
    } catch (err) {
        console.log('error resending oneTimeCode: ', err);
        return false;
    }
};

/**
 * Verify user using oneTimeCode and email
 * @param userEmail
 * @param oneTimeCode
 * @returns {Promise<boolean>}
 */
export const confirmSignUp = async (userEmail, oneTimeCode) => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js confirmSignUp');
    try {
        await Auth.confirmSignUp({username: userEmail, confirmationCode: oneTimeCode});
        // console.log('email verified');
        return true;
    } catch (err) {
        console.log('error confirmSignUp: ', err);
        return false;
    }
};

/**
 * Login user
 * @param username
 * @param password
 * @param showCatchErrorHere
 * @returns {Promise<any>}
 */
export const loginUser = async (username, password, showCatchErrorHere = true) => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js loginUser');
    let loginResponse;
    if (showCatchErrorHere) {
        loginResponse = await Auth.signIn({username, password}).catch((e) => {
            if (typeof $?.jnoty !== 'undefined') {
                AppUtilsFunctions.showAwsExceptionMessage(e);
            } else {
                AppUtilsFunctions.showAwsExceptionToastMessage(e);
            }
        });
    } else {
        loginResponse = await Auth.signIn({username, password});
    }

    if (loginResponse?.isSignedIn === true) {
        loginResponse = {...loginResponse, ...(await Auth.getCurrentUser())};
    }

    // console.log('loginUser loginResponse', loginResponse);

    if (loginResponse?.nextStep?.signInStep === 'NEW_PASSWORD_REQUIRED' || loginResponse?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {

        loginResponse = await Auth.confirmSignIn({
            challengeResponse: password
        }).catch((e) => {
            console.log('loginUser confirmSignIn error: ', e);
            if (typeof $?.jnoty !== 'undefined') {
                AppUtilsFunctions.showAwsExceptionMessage(e);
            } else {
                AppUtilsFunctions.showAwsExceptionToastMessage(e);
            }
            return undefined;
        });

        if (loginResponse?.isSignedIn === true) {
            loginResponse = {...loginResponse, ...(await Auth.getCurrentUser())};
        }
    }

    return loginResponse;
};

/**
 * Sing-out logged-in user
 * @param globalLogout
 * @returns {Promise<any>}
 */
export const logoutUser = async (globalLogout = false) => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js logoutUser');
    let internetAvailable = AppUtilsFunctions.isActiveInternetConnection();
    if (internetAvailable) {
        let signOutOptions = {};
        if (globalLogout) {
            signOutOptions = {global: true};
        }
        return await Auth.signOut(signOutOptions).catch((e) => {
            if (typeof $?.jnoty !== 'undefined') {
                AppUtilsFunctions.showAwsExceptionMessage(e);
            } else {
                AppUtilsFunctions.showAwsExceptionToastMessage(e);
            }
        });
    } else {
        return false;
    }
};

/**
 * Check user logged in or not.
 * If returnUserCred equals to true then return the logged-in user credentials with status.
 * @param returnUserCred
 * @returns {Promise<*|boolean>}
 */
export const isLoggedIn = async (returnUserCred = false) => {

    let currentAuth = false;

    try {

        await AmplifyConfigure.ensureConfigured('amplify-auth.js isLoggedIn');

        if (AppUtilsFunctions.isActiveInternetConnection()) {

            const authSession = await Auth.fetchAuthSession();

            // console.log('authSession', authSession);

            // if (AppUtilsFunctions.isDefined(authSession, 'tokens.idToken.payload.sub')) {

            if (AppUtilsFunctions.isDefined(authSession, 'userSub')) {
                currentAuth = returnUserCred ? {
                    sub: authSession.userSub,
                    username: authSession.userSub,
                    email: authSession?.tokens?.idToken?.payload?.email
                } : true;
            } else {
                currentAuth = false;
            }

        } else {

            const {username, userId, signInDetails} = await Auth.getCurrentUser(); // only works after auth signIn

            currentAuth = returnUserCred ? {
                sub: userId,
                username: username,
                email: signInDetails.loginId
            } : true;
        }

        return currentAuth;

    } catch (error) {
        console.log('Error isLoggedIn: ', error);

        switch (error.name) {
            case 'UserUnAuthenticatedException':
                currentAuth = false;
                break;
            case 'Network error':
                currentAuth = false;
                break;
            default:
                currentAuth = false;
                break;
        }

        return currentAuth;
    }
};

/**
 * Auth remember device
 * @returns {Promise<void>}
 */
export const rememberDevice = async () => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js rememberDevice');
    try {
        const result = await Auth.rememberDevice();
        console.log(result);
    } catch (error) {
        console.log('Error remembering device', error);
    }
};

/**
 * Remove devices
 * @returns {Promise<boolean>}
 */
export const forgetDevice = async () => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js forgetDevice');
    try {
        const result = await Auth.forgetDevice();
        // console.log(result);
        return true;
    } catch (error) {
        console.log('Error forgetting device', error);
        return false;
    }
};

/**
 * Delete Auth User from AWS
 * @returns {Promise<boolean>}
 */
export const deleteAuthUser = async () => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js deleteAuthUser');
    try {
        const result = await Auth.deleteUser();
        // console.log(result);
        return true;
    } catch (error) {
        console.log('Error deleting user', error);
        return false;
    }
};

/**
 * Update email of authenticated user
 * @param newEmail
 * @returns {Promise<boolean|string>}
 */
export const updateAuthUserEmail = async (newEmail) => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js updateAuthUserEmail');
    return await Auth.updateUserAttributes({
        userAttributes: {
            email: newEmail
        }
    }).then(() => {
        // console.log('a verification oneTimeCode is sent');
        return true;
    }).catch((e) => {
        console.log('failed with error', e);
        if (typeof $?.jnoty !== 'undefined') {
            AppUtilsFunctions.showAwsExceptionMessage(e);
        } else {
            AppUtilsFunctions.showAwsExceptionToastMessage(e);
        }
        return e?.name || false;
    });
};

/**
 * Change password of current auth user
 * @param oldPassword
 * @param newPassword
 * @returns {Promise<boolean>}
 */
export const changeAuthUserPassword = async (oldPassword, newPassword) => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js changeAuthUserPassword');
    return Auth.updatePassword({oldPassword, newPassword}).then((data) => {
        // console.log(data);
        return true;
    }).catch((err) => {
        if (typeof $?.jnoty !== 'undefined') {
            AppUtilsFunctions.showAwsExceptionMessage(err);
        } else {
            AppUtilsFunctions.showAwsExceptionToastMessage(err);
        }
        return false;
    });
};

/**
 * Sent re authentication oneTimeCode to auth user
 * @returns {Promise<boolean>}
 */
export const sendVerificationCodeToCurrentAuthUser = async () => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js sendVerificationCodeToCurrentAuthUser');
    return await Auth.sendUserAttributeVerificationCode({userAttributeKey: 'email'}).then(() => {
        // console.log('a verification oneTimeCode is sent');
        return true;
    }).catch((e) => {
        console.log('failed with error', e);
        if (typeof $?.jnoty !== 'undefined') {
            AppUtilsFunctions.showAwsExceptionMessage(e);
        } else {
            AppUtilsFunctions.showAwsExceptionToastMessage(e);
        }
        return false;
    });
};

/**
 * Re authenticate current user by oneTimeCode
 * @param oneTimeCode
 * @returns {Promise<boolean>}
 */
export const verifyCurrentUserAuthByCode = async (oneTimeCode) => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js verifyCurrentUserAuthByCode');
    return await Auth.confirmUserAttribute({confirmationCode: oneTimeCode, userAttributeKey: 'email'}).then(() => {
        // console.log('email verified');
        return true;
    }).catch((e) => {
        console.log('failed with error', e);
        if (typeof $?.jnoty !== 'undefined') {
            AppUtilsFunctions.showAwsExceptionMessage(e);
        } else {
            AppUtilsFunctions.showAwsExceptionToastMessage(e);
        }
        return false;
    });
};

/**
 * Send forgot password verification oneTimeCode by email
 * @param username
 * @returns {Promise<boolean>}
 */
export const sendForgotPasswordCode = async (username) => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js sendForgotPasswordCode');
    return await Auth.resetPassword({username})
        .then((data) => {
            console.log('sendForgotPasswordCode data', data);
            return true;
        })
        .catch((e) => {
            console.log('failed with error', e);
            if (typeof $?.jnoty !== 'undefined') {
                AppUtilsFunctions.showAwsExceptionMessage(e);
            } else {
                AppUtilsFunctions.showAwsExceptionToastMessage(e);
            }
            return false;
        });
};

/**
 * Verify forgot password oneTimeCode and change the password with new password string
 * @param username
 * @param oneTimeCode
 * @param newPassword
 * @returns {Promise<boolean>}
 */
export const verifyForgotPasswordCode = async (username, oneTimeCode, newPassword) => {
    await AmplifyConfigure.ensureConfigured('amplify-auth.js verifyForgotPasswordCode');
    return await Auth.confirmResetPassword({username, confirmationCode: oneTimeCode, newPassword})
        .then((data) => true)
        .catch((e) => {
            console.log('failed with error', e);
            if (typeof $?.jnoty !== 'undefined') {
                AppUtilsFunctions.showAwsExceptionMessage(e);
            } else {
                AppUtilsFunctions.showAwsExceptionToastMessage(e);
            }
            return false;
        });
};

/**
 * Check auth token expired or not.
 * If expired then refresh it.
 * @param checkConfiguration
 * @param showSessionLog
 * @returns {Promise<*|boolean>}
 */
export const checkAuthTokenExpiration = async (checkConfiguration = true, showSessionLog = false) => {
    try {

        if (checkConfiguration) {
            await AmplifyConfigure.ensureConfigured('auth-db.js checkAuthTokenExpiration');
        }

        const authSession = await Auth.fetchAuthSession();
        const authIdToken = authSession.getIdToken();

        const currentTime = Math.floor(new Date().getTime() / 1000);
        const expiration = authIdToken.payload.exp;

        if (showSessionLog) {
            console.log('=== checkAuthTokenExpiration -> authSession: ', authSession);
            console.log('=== checkAuthTokenExpiration -> authIdToken: ', authIdToken);
            console.log('=== checkAuthTokenExpiration -> currentTime: ', currentTime);
            console.log('=== checkAuthTokenExpiration -> expiration: ', expiration);
        }

        if (currentTime >= expiration) {
            const res = await refreshAuthToken(showSessionLog);
            console.log('=== checkAuthTokenExpiration -> res:', res);
            return res.status;
        } else {
            console.log('=== checkAuthTokenExpiration -> Token is still valid.');
            return true;
        }
    } catch (error) {
        console.error('=== checkAuthTokenExpiration -> Error:', error);
        return false;
    }
};

/**
 * Refresh current auth token
 * @param showSessionLog
 * @returns {Promise<unknown>}
 */
export const refreshAuthToken = async (showSessionLog = false) => {
    try {
        const authSession = await Auth.fetchAuthSession({forceRefresh: true});

        if (showSessionLog) {
            console.log('=== refreshAuthToken -> authSession: ', authSession);
        }

        return {status: true, session: authSession, err: {}};

    } catch (error) {
        console.log('=== refreshAuthToken -> refreshSession -> catch error: ', error);
        return {status: false, session: {}, err: error};
    }
};

/**
 * Listen Auth events
 * @param clearLocalData
 */
export const listenAuthEvents = (clearLocalData = false) => {
    Hub.listen('auth', async ({payload}) => {
        // console.log(payload);
        const {event} = payload;
        switch (event) {
            case 'configured':
                console.log('the Auth module is configured');
                break;
            case 'signIn':
                console.log('user signed in');
                break;
            case 'signIn_failure':
                console.log('user sign in failed');
                break;
            case 'signUp':
                console.log('user signed up');
                break;
            case 'signUp_failure':
                console.log('user sign up failed');
                break;
            case 'confirmSignUp':
                console.log('user confirmation successful');
                break;
            case 'completeNewPassword_failure':
                console.log('user did not complete new password flow');
                break;
            case 'autoSignIn':
                console.log('auto sign in successful');
                break;
            case 'autoSignIn_failure':
                console.log('auto sign in failed');
                break;
            case 'forgotPassword':
                console.log('password recovery initiated');
                break;
            case 'forgotPassword_failure':
                console.log('password recovery failed');
                break;
            case 'forgotPasswordSubmit':
                console.log('password confirmation successful');
                break;
            case 'forgotPasswordSubmit_failure':
                console.log('password confirmation failed');
                break;
            case 'tokenRefresh':
                console.log('token refresh succeeded');
                break;
            case 'tokenRefresh_failure':
                console.log('token refresh failed');
                break;
            case 'cognitoHostedUI':
                console.log('Cognito Hosted UI sign in successful');
                break;
            case 'cognitoHostedUI_failure':
                console.log('Cognito Hosted UI sign in failed');
                break;
            case 'customOAuthState':
                console.log('custom state returned from CognitoHosted UI');
                break;
            case 'customState_failure':
                console.log('custom state failure');
                break;
            case 'parsingCallbackUrl':
                console.log('Cognito Hosted UI OAuth url parsing initiated');
                break;
            case 'userDeleted':
                console.log('user deletion successful');
                break;
            case 'signOut':
                console.log('user signed out');
                if (clearLocalData) {
                    await DataStore.clear();
                }
                break;
        }
    });
};

/**
 * Encode payloads in AES method of JWT_CRYPTO
 * @param payload
 * @param validationText
 * @param urlSafe
 * @returns {null|string}
 */
export const encodeJWTPayload = (payload, validationText = 'saleProTokenAuth', urlSafe = false) => {
    try {
        let encryptedData = JWT_CRYPTO.AES.encrypt(JSON.stringify(payload), validationText);

        // Get the encrypted data as a base64-encoded string
        let base64EncryptedData = encryptedData.toString();

        if (urlSafe) {
            // Convert the base64-encoded string to a URL-safe format
            return base64EncryptedData.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
        } else {
            return base64EncryptedData;
        }
    } catch (e) {
        console.log('encodeJWTPayload', e);
        return null;
    }
};

/**
 * Decode payloads AES method of JWT_CRYPTO
 * @param token
 * @param validationText
 * @param urlSafe
 * @returns {{}|any}
 */
export const decodeJWTPayload = (token, validationText = 'saleProTokenAuth', urlSafe = false) => {
    try {
        if (urlSafe) {
            // Convert the URL-safe encrypted data back to standard base64 format
            token = token.replace(/-/g, '+').replace(/_/g, '/').padEnd(token.length + 4 - (token.length % 4), '=');
        }

        let bytes = JWT_CRYPTO.AES.decrypt(token, validationText);

        return JSON.parse(bytes.toString(JWT_CRYPTO.enc.Utf8));

    } catch (e) {
        console.log('decodeJWTPayload', e);
        return {};
    }
};
